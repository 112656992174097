<template>
  <v-app>
    <div class="container" v-show="shows.showNotFound">
      <div class="col-12" :style="{ height: windowWidth }">
        <div class="col-12 text-center" style="margin-top: 15%; height: 120px">
          <p class="h1 text-center" :class="errColor">
            {{ err[erri] }}
          </p>
        </div>
      </div>
    </div>

    <div v-show="shows.loader">
      <div
          class="d-flex justify-content-center mx-auto"
          style="max-width: 800px; height: 700px"
      >
        <div
            class="spinner-border h1 text-primary"
            role="status"
            style="height: 90px; width: 90px; margin-top: 25%"
        ></div>
      </div>
    </div>

    <div v-show="shows.showProfile">
      <!-- v-show="shows.showProfile" -->
      <section class="container-sm">
        <!-- nav bar section -->

        <!-- <nav class="navbar navbar-light bg-light d-flex flex-row">
        <div class="col-sm-3 col-6 header-logo">
          <img src="./logotype_full.png" alt="" class="w-100 cursor" />
        </div>

        <div
          class="col-sm-3 col-6 text-right d-sm-none"
          id="second-signin-responsive"
        >
          <router-link to="login" class="text-decoration mx-1 h5"
            >ثبت نام / ورود</router-link
          >
        </div>

        <div
          class="col-sm-6 col-12 mx-auto d-flex flex-sm-row-reverse flex-column"
        >
          <form class="">
            <input
              type="text"
              placeholder="جستجو"
              id="search-box"
              class="mx-auto float-right"
              v-model="searchbar"
            />
        
          <button
            v-on:click="getDoctorInformation"
            type="button"
            class="btn btn-primary d-sm-block ms-3"
            style="height: 40px; margin-top: 2px"
            id="search-btn-responsive"
          >
            جستجو
          </button>
            </form>

        </div>

        <div
          class="col-sm-3 col-12 text-right d-sm-block d-none"
          id="main-login-signin-responsive"
        >
          <router-link to="login" class="text-decoration mx-1 h5"
            >ثبت نام / ورود</router-link
          >
        </div>
      </nav> -->

        <!-- end of the (nav) -->

        <header class="container-fluid row mx-auto mt-2">
          <div class="col-sm-4 col-xs-12 d-flex">
            <v-avatar
                class="profile mt-2"
                size="150"
                :class="isMobile() ? 'mx-auto' : 'ms-auto'"
            >
              <v-img
                  :src="
                  Profile.pic != null && Profile.pic !== ''
                    ? Profile.pic
                    : require('@/assets/images/profile.jpg')
                "
              ></v-img>
            </v-avatar>
          </div>
          <div class="col-sm-8 col-xs-12">
            <div>
              <div class="col-12 mt-2 py-0">
                <span class="h4 font-weight-bold">
                  {{ Profile.firstname + " " + Profile.lastname }}
                </span>
              </div>
              <div class="col-12 mt-2 py-0">
                <span class="h4 font-weight-bold ltr">
                  {{ Profile.username }}@
                </span>
              </div>
              <div class="col-12 d-flex align-items-center mt-3">
                <div class="text-center">
                  <span class="font-weight-bold">{{ Profile.postCount }}</span>
                  <span class="font-weight-light"> پست </span>
                </div>
                <div class="text-center mx-5">
                  <span class="font-weight-bold">{{
                      Profile.followerCount
                    }}</span>
                  <span class="font-weight-light"
                  >{{ follow.digit[fCounter] }} دنبال کننده</span
                  >
                </div>
                <div class="text-center">
                  <span class="font-weight-bold">{{
                      Profile.questionCount
                    }}</span>
                  <span class="font-weight-light"> سوال پاسخ داده شده</span>
                </div>
              </div>
              <div class="col-12">
                <p class="mt-1 text-secondary">
                  <span v-for="(sp, index) in Profile.specialties" :key="index">
                    {{ sp.value }}
                    <span v-if="index !== Profile.specialties.length - 1"
                    >|</span
                    >
                  </span>
                </p>
                <p class="text-justify">
                  {{ Profile.bio }}
                </p>
                <!-- <a
                  href="https://www.mindology.io"
                  class="text-primary"
                  target="_blank"
                  >mindology.io</a
                > -->
                <!-- <div class="col-12 border border-primary">
                  <button type="button" class="btn btn-primary col-3 text-center">
                    شماره متخصص
                  </button>
                </div> -->
              </div>
              <div class="d-flex col-12 gap-3">
                <v-btn
                    color="success"
                    class=" col-sm-3 col-6 text-center"
                    v-b-modal.modal-center
                    style="font-size: 16px"
                    v-if="PhoneNumbers !== false"
                    @click="ModalShow = true"
                    variant="success"
                >
                  شماره تماس
                </v-btn>
                <v-btn
                    color="primary"
                    class=" col-sm-3 col-6 text-center"
                    v-b-modal.modal-center
                    style="font-size: 16px"
                    v-if="doctorAppointment === true"
                    @click="AppointmentModal = true"
                    variant="success"
                >
                  نوبت گیری
                </v-btn>
              </div>
            </div>
          </div>
        </header>
        <div>
          <b-modal
              body-class="text-center"
              footer-class="text-center"
              dialog-class="text-center"
              title-class="text-center"
              hide-header-close
              v-model="ModalShow"
              centered
          >
            <b-container fluid>
              <p class="text-success f18 fw-bold">
                {{ Profile.contactDescription }}
              </p>
              <p
                  class="my-2"
                  v-for="(number, index) in PhoneNumbers"
                  :key="index"
              >
                <v-btn
                    elevation="2"
                    outlined

                    rounded
                    class="f16 fw-bold">
                  <v-icon left>
                    mdi-phone
                  </v-icon>
                  {{ number }}
                </v-btn>
              </p>
            </b-container>
            <template #modal-header>
              <div class="w-100 text-center">شماره تماس</div>
            </template>

            <template #modal-footer class="my-0">
              <b-row class="w-100 my-0" align-v="center">
                <b-col class="col-12">
                  <p class="text-danger" style="font-size: 14px">
                    در مواقع بحرانی مانند هرگونه کودک آزاری، همسرآزاری و یا
                    افکار خودکشی بدون صبر با شماره 123 اورژانس اجتماعی تماس
                    بگیرید
                  </p>
                  <b-button
                      size="md"
                      class="mb-3 btn text-light"
                      @click="ModalShow = false"
                  >
                    باشه
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-modal>
        </div>
        <div>
          <b-modal
              body-class="text-center"
              footer-class="text-center"
              dialog-class="text-center"
              title-class="text-center"
              hide-header-close
              v-model="AppointmentModal"
              centered
          >
            <template #modal-header>
              <div class="w-100 text-center">نوبت گیری</div>
            </template>
            <b-container fluid>
              <p>
                برای گرفتن نوبت ابتدا باید وارد سایت شوید. برای ورود به سایت اینجا <a :href="mindologyURL" target="_blank">کلیک</a>
                کنید.
              </p>
            </b-container>
            <template #modal-footer class="my-0">
              <b-row class="w-100 my-0" align-v="center">
                <b-col class="col-12">
                  <b-button
                      size="md"
                      class="mb-3 btn text-light"
                      @click="AppointmentModal = false"
                  >
                    باشه
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-modal>
        </div>
        <main class="row mx-auto">
          <v-tabs class="col-12 text-center" v-model="tab" fixed-tabs>
            <v-tabs-slider color="blue"></v-tabs-slider>
            <v-tab
                href="#tab-1"
                active-class="blue--text"
                class="text-decoration-none"
                aria-selected="true"
            >
              مطالب ({{ Profile.postCount }})
            </v-tab>

            <v-tab
                href="#tab-2"
                active-class="blue--text"
                class="text-decoration-none"
            >
              پاسخ ها ({{ Profile.questionCount }})
            </v-tab>
            <!-- <span class="cursor" :style="select.video">
              ویدئو
            </span> -->
          </v-tabs>
          <!-- start post loader  -->

          <div
              class="mx-auto text-center"
              style="height: 165px; max-width: 800px"
              v-show="shows.noneErrPost"
          >
            <div
                class="h3"
                role="status"
                style="height: 50px; width: auto; margin-top: 8%"
                :class="ErrPostColor"
            >
              {{ err[erriPost] }}
            </div>
          </div>

          <v-tabs-items v-model="tab" class="col-12">
            <v-tab-item value="tab-1">
              <div
                  class="mx-auto text-center"
                  style="height: 165px; max-width: 800px"
                  v-show="shows.primaryPostsLoader"
              >
                <div
                    class="spinner-border text-primary h1"
                    role="status"
                    style="height: 50px; width: 50px; margin-top: 8%"
                ></div>
              </div>

              <!-- end of the post loader -->

              <!-- post counter show -->
              <b-container v-show="shows.posts">
                <b-row>
                  <b-col
                      v-for="(post, index) in posts"
                      :key="index"
                      class="d-flex child-flex"
                      md="4"
                      sm="12"
                      xs="12"
                  >
                    <router-link
                        :to="
                        '/' +
                        $route.params.username +
                        '/' +
                        post.type.toLowerCase() +
                        '/' +
                        post.id
                      "
                        :href="post.title"
                        class="text-decoration-none"
                    >
                      <!-- @click="singlePost(post)" -->
                      <v-card elevation="3">
                        <v-img
                            :src="post.picture"
                            aspect-ratio="1"
                            class="d-flex align-items-center"
                        >
                          <div class="text-white w-100 text-center">
                            <i
                                class="fas fa-play p-4 bg-black-50 rounded-pill"
                                id="playicon"
                                v-if="post.type == 'VIDEO'"
                            ></i>
                          </div>
                        </v-img>
                        <span class="d-block py-4 px-2">
                          {{ post.title }}
                        </span>
                      </v-card>
                    </router-link>
                  </b-col>
                </b-row>
              </b-container>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <div
                  class="mx-auto text-center"
                  v-show="shows.primaryPostsLoader"
              >
                <div
                    class="spinner-border text-primary h1"
                    role="status"
                    style="height: 50px; width: 50px; margin-top: 8%"
                ></div>
              </div>

              <!-- end of the post loader -->

              <!-- post counter show -->
              <b-container v-show="shows.questions">
                <b-row>
                  <b-col
                      v-for="(question, index) in questions.slice().reverse()"
                      :key="index"
                      class="d-flex child-flex"
                      xl="4"
                      lg="4"
                      md="6"
                      sm="12"
                      xs="12"
                  >
                    <router-link
                        :to="
                        '/' +
                        $route.params.username +
                        '/question/' +
                        question.id
                      "
                        :href="
                        '/' +
                        $route.params.username +
                        '/answer-question/' + 'id='+
                        question.id
                      "
                        class="text-decoration-none"
                    >
                      <!-- @click="singleQuestion(question)" -->
                      <v-card elevation="3" class="text-decoration-none h-100">
                        <div
                            class="col-12 border rounded d-flex flex-column h-100"
                        >
                          <div
                              class="
                              d-flex
                              flex-row
                              justify-content-between
                              mb-2
                              align-items-center
                            "
                          >
                            <span
                                class="
                                small
                                font-weight-bold
                                light-blue
                                lighten-5
                                light-blue--text
                                text--darken-3
                                rounded
                                px-2
                                py-1
                              "
                            >
                              {{ question.answers.length }} پاسخ
                            </span>
                            <span
                                class="
                                small
                                font-weight-light
                                text-secondary
                                rounded
                                p-0
                                mt-2
                              "
                            >
                              {{ DateCalculate(question.createDate) }}
                            </span>
                          </div>

                          <div
                              class="
                              p-2
                              small
                              font-weight-bold
                              border
                              rounded
                              flex-grow-1
                            "
                          >
                            <p class="text-right my-0">
                              {{ question.question }}
                            </p>
                          </div>
                          <div class="mt-2">
                            <div class="d-flex flex-row align-items-center">
                              <section class="d-flex flex-row avatar-group rtl">
                                <span
                                    v-for="(
                                    answer, index
                                  ) in question.answers.slice(0, 3)"
                                    :key="index"
                                    class="avatars-group__item avatar"
                                >
                                  <v-avatar
                                      size="36"
                                      color="indigo"
                                      class="bordered"
                                  >
                                    <img
                                        :src="answer.doctorProfilePic"
                                        :alt="answer.doctorFullName"
                                        v-if="
                                        answer.doctorProfilePic && index < 2
                                      "
                                    />

                                    <span
                                        v-else-if="index < 2"
                                        class="white--text text-small"
                                    >
                                      {{ answer.doctorFullName.slice(0, 1) }}
                                    </span>

                                    <span v-else class="white--text text-small">
                                      ...
                                    </span>
                                  </v-avatar>
                                </span>
                              </section>
                              <div>
                                <span
                                    class="text-small font-weight-normal ps-1"
                                >
                                  {{ question.answers[0].doctorFullName }}
                                </span>
                                <span
                                    class="
                                    text-x-small text-secondary
                                    font-weight-light
                                  "
                                    v-if="question.answers.length > 1"
                                >
                                  و {{ question.answers.length - 1 }} نفر دیگر
                                </span>
                              </div>
                            </div>

                            <div class="text-left mt-1">
                              <div
                                  class="
                                  d-flex d-inline-flex
                                  align-items-center
                                  bg-secondary
                                  px-1
                                  rounded
                                  text-white
                                "
                              >
                                <i class="fas fa-heart mx-1 fa-sm"></i>
                                <span class="me-1 text-white text-small">{{
                                    question.userLikeQuestions.length
                                  }}</span>
                              </div>
                              <div
                                  class="
                                  d-flex d-inline-flex
                                  align-items-center
                                  bg-secondary
                                  px-1
                                  rounded
                                  text-white
                                  ms-2
                                "
                              >
                                <i class="fas fa-eye mx-1 fa-sm"></i>
                                <span class="me-1 text-white text-small">{{
                                    question.view
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </router-link>
                  </b-col>
                </b-row>
              </b-container>
            </v-tab-item>
          </v-tabs-items>
        </main>
      </section>
    </div>
    <!-- end of the post  -->
  </v-app>
</template>
<script>
import apiService from "@/http/ApiServiceWebsite.js";
import router from "vue-router";
import Vue from "vue";

Vue.use(router);
var moment = require("moment");

export default {
  data() {
    return {
      mindologyURL: process.env.VUE_APP_EXTERNAL_URL,
      doctorAppointment: false,
      AppointmentModal: false,
      ModalShow: false,
      tab: "tab-1",
      postHolder: {},
      PhoneNumbers: [],
      slide: 0,
      sliding: null,
      height: "100px",
      windowWidth: window.innerHeight,
      finalheight: "",
      searchbar: "",
      success: false,
      blockMSG: ["آیا مطمئن هستید ؟", "صفحه مورد نظر با موفقیت مسدود شد"],
      test: "",
      commenttest: "test",
      your_comment_input: "",
      fCounter: 2,
      follow: {
        followbtn: ["لغو دنبال", "+ دنبال کردن"],
        followbtncounter: 1,
        btn: "btn btn-primary",
        digit: ["هزار", "میلیون", ""],
      },
      shows: {
        postshow: false,
        blockbtn: true,
        unlike: true,
        like: false,
        Tbookmark: false,
        Fbookmark: true,
        showProfile: false,
        showNotFound: false,
        postLoader: true,
        loader: true,
        postLoading: false,
        primaryPostsLoader: true,
        posts: false,
        questions: false,
        VideoAll: true,
        dataloader: false,
        DESloader: true,
        postImage: false,
        imageCounter: false,
        imageShow: false,
        noneErrPost: false,
        videoPlayerShow: false,
      },
      select: {
        video: {
          color: "#000",
        },
        all: {
          color: "#1976D2",
        },
        questions: {},
        block: {
          color: "000",
          counter: 0,
        },
      },
      Profile: {},
      posts: [],
      questions: [],
      err: [
        " خطا در پردازش ",
        "صفحه مورد نظر یافت نشد !",
        "محتوایی یافت نشد !",
      ],
      erri: 0,
      errColor: "text-danger",
      erriPost: 2,

      images: [],
      currentIndex: 0,
      currentImageSlideNumber: 1,
      imageArrayLength: 0,
      chooseSlide: "",
      ErrPostColor: "",
      DataTest: [],
    };
  },
  methods: {
    DateCalculate(then) {
      var now = new Date();
      var diffDays = moment(now).diff(moment(then), "days");
      if (diffDays < 1) {
        return "امروز";
      } else if (diffDays > 1 && diffDays < 7) {
        return diffDays + " روز پیش";
      } else if (diffDays >= 7 && diffDays <= 28) {
        return Math.round(diffDays / 7) + " هفته پیش";
      } else if (diffDays > 28 && diffDays <= 365) {
        return Math.round(diffDays / 30) + " ماه پیش";
      } else if (diffDays > 365) {
        return Math.round(diffDays / 365) + " سال پیش";
      } else {
        return diffDays + " روز پیش";
      }
    },
    // singlePost(post) {
    //   if (post.type == "VIDEO") {
    //     apiService.VideoView(post.id);
    //     this.$router.push({
    //       path: "/" + this.$route.params.username + "/video/" + post.id,
    //     });
    //   } else {
    //     apiService.PostView(post.id);
    //     this.$router.push({
    //       path: "/" + this.$route.params.username + "/post/" + post.id,
    //     });
    //   }
    // },
    singleQuestion(question) {
      this.$router.push({
        path: "/" + this.$route.params.username + "/question/" + question.id,
      });
    },
    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },
    sliderloader(pic) {
      this.images = [];
      if (typeof pic === "string") {
        this.images.push(pic);
      }
      this.shows.postImage = true;
      this.shows.imageShow = true;

      if (this.images.length > 1) {
        this.shows.imageCounter = true;
      } else {
        this.shows.imageCounter = false;
      }
      let a = Math.abs(this.currentIndex) % this.images.length;
      this.currentImageSlideNumber = a + 1;
      let f = Math.abs(this.currentIndex) % this.images.length;
      this.chooseSlide = this.images[f];
    },

    getdoctorPublishedContentList(doctorId) {
      apiService
          .doctorPublishedContentList(doctorId)
          .then((DATA) => {
            this.posts = DATA.data;
            this.shows.primaryPostsLoader = false;
            this.shows.posts = true;
          })
          .catch((err) => {
            if (err.response.status == 404 || err.response.status == 409) {
              this.shows.primaryPostsLoader = false;
              this.shows.posts = false;
              this.shows.noneErrPost = true;
              this.erriPost = 2;
              this.VideoAll = false;
              // this.posts.id
            } else {
              this.shows.primaryPostsLoader = false;
              this.shows.posts = false;
              this.shows.noneErrPost = true;
              this.erriPost = 0;
              this.ErrPostColor = "text-danger";
            }
          });
    },
    getdoctorAnswerQuestionsList(doctorId) {
      apiService
          .doctorAnswerQuestionsList(doctorId)
          .then((DATA) => {
            this.questions = DATA.data.content;
            this.shows.questions = true;
          })
          .catch((err) => {
            if (err.response.status == 404 || err.response.status == 409) {
              this.shows.questions = false;
              // this.posts.id
            } else {
              this.shows.questions = false;
            }
          });
    },
    // postLoader(x) {
    //   if (x.type == "VIDEO") {
    //     apiService.videoInfo(x.id).then((DATA) => {
    //       if (DATA.status == 200) {
    //         this.postHolder = DATA.data;
    //         this.shows.videoPlayerShow = true;

    //         this.shows.postLoader = false;
    //         this.shows.DESloader = false;
    //         this.shows.dataloader = true;
    //       }
    //     });
    //   } else if (x.type == "POST") {
    //     apiService.postInfo(x.id).then((DATA) => {
    //       if (DATA.status == 200) {
    //         this.postHolder = DATA.data;
    //         this.sliderloader(DATA.data.picture);

    //         this.shows.DESloader = false;
    //         this.shows.dataloader = true;
    //         this.shows.postLoader = false;
    //       }
    //     });
    //   }
    // },
    getDoctorInformation(username) {
      apiService
          .getDoctorInfo(username)
          .then((DATA) => {
            if (DATA.status === 200) {
              this.doctorAppointment = DATA.data.registered
              // this.$route.meta.title = "asdasd";
              document.title =
                  "مایندولوژی | " + DATA.data.firstname + " " + DATA.data.lastname;
              if (DATA.data.phone != null || "") {
                this.PhoneNumbers = DATA.data.phone.split(",");
              } else {
                this.PhoneNumbers = false;
              }

              this.shows.showProfile = true;
              this.shows.loader = false;
              this.shows.showNotFound = false;
              this.Profile = DATA.data;
              if (DATA.data.followerCount <= 999) {
                this.fCounter = 2;
              } else if (
                  DATA.data.followerCount >= 1000 &&
                  DATA.data.followerCount <= 999999
              ) {
                this.fCounter = 1;
              } else {
                this.fCounter = 0;
              }
              this.getdoctorPublishedContentList(DATA.data.id);
              this.getdoctorAnswerQuestionsList(DATA.data.id);
            } else {
              this.erri = 1;
            }
          })
          .catch((error) => {
            this.shows.loader = false;
            this.shows.showProfile = false;
            this.shows.showNotFound = true;
            this.errColor = "text-danger";
            if (error.response.status == 422 || error.response.status == 500) {
              this.erri = 1;
            } else {
              this.erri = 0;
            }
          });
    },
    isMobile() {
      if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
          )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    let user = this.$route.params.username;
    this.getDoctorInformation(user);
  },
};
</script>
<style lang="scss">
.avatars-group__item {
  cursor: default;
  transition: all 0.1s ease-out;

  &.more {
    align-items: center;
    display: flex;

    &:hover {
      transform: none;
    }
  }

  &:hover {
    transform: translateY(-4px);
    z-index: 1;
  }
}

.v-avatar {
  &.bordered {
    box-shadow: 0px 0px 0px 2px #fff inset;

    img {
      padding: 2px;
    }

    &.small {
      box-shadow: 0px 0px 0px 1px #fff inset;

      img {
        padding: 1px;
      }
    }
  }
}

.avatar-group > :not(:first-child) {
  margin-right: -12px !important;
}
</style>
